import { Scene } from './Scene.tsx';
import { Canvas, useThree } from '@react-three/fiber';
      

export function App() {
  return(
    <Canvas camera={{ fov: 75, position: [0.05, 2.05, -0.1]}}>
      <Scene/>
    </Canvas>
  );
}