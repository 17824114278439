import { Environment, Lightformer } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import React, { useEffect, useRef } from "react";
import { PointLightHelper, Vector3 } from "three";
import hdr from "../public/studio.hdr"


export function Lighting({sRef}){
  const light = useRef<THREE.PointLight>(null!)
  const {scene} = useThree()
  useEffect(()=>{
    const helper = new PointLightHelper(light.current, 2, 0x000000)
    scene.add(helper)
  })
  return(
    <>
      <mesh ref={sRef} position={[5,19,-50]}>
        <sphereGeometry args={[4,16,16]}></sphereGeometry>
        <meshBasicMaterial 
          color={0xffddaa}
          transparent={true}
          fog={false}
        />
        <pointLight ref={light}/>
      </mesh>
      <Environment files={hdr}/>
    </>
  )
}