/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\Users\kuro\Desktop\web\bday\eason\public\hand.gltf --transform -k s
*/
import { Html, useAnimations, useGLTF } from '@react-three/drei'
import handUrl from "../public/hand.glb"
import normal_texture from "../public/skin_normal.png"
import * as THREE from 'three'
import { GLTF } from 'three-stdlib'
import { useEffect, useRef } from 'react'
import { useThree } from '@react-three/fiber'
import { Euler, Material, Texture } from 'three'

type ActionName = 'open' | 'open_to_middle' | 'open_to_thumbs' | 'show_middle_finger' | 'thumbs_up' | 'thumbs_up.002'

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName;
}
type GLTFResult = GLTF & {
  nodes: {
    Icosphere001: THREE.SkinnedMesh
    Icosphere001_1: THREE.SkinnedMesh
    index_0: THREE.Bone
    middle_0: THREE.Bone
    ring_0: THREE.Bone
    pinky_0: THREE.Bone
    thumb_0: THREE.Bone
  }
  materials: {
    skin: THREE.MeshStandardMaterial
    nail: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[];
}
export function Hand({funcRef}) {
  const group = useRef<THREE.Group>(null!)
  const sphere0 = useRef<THREE.SkinnedMesh>(null!)
  const sphere1 = useRef<THREE.SkinnedMesh>(null!)
  const { nodes, materials, animations } = useGLTF(handUrl) as GLTFResult
  const {actions, mixer} = useAnimations(animations, group)
  const {scene} = useThree()
  const normalTexture = new THREE.TextureLoader().load(normal_texture)
  materials.skin.normalMap = normalTexture
  materials.skin.envMapIntensity = 0.1
  useEffect(()=>{
    actions.open!.play()
  })

  funcRef.current = function morphMiddleFinger(){
    actions.open!.crossFadeTo(actions.show_middle_finger!.play(), 1, false)
  }

  function morphThumbsUp(){
    actions.open!.crossFadeTo(actions.thumbs_up!.play(), 0.5, false)
  }

  const nailRef = useRef<THREE.SkinnedMesh>(null!)
  return (
    <group rotation={new Euler(0,Math.PI/2,0)} ref={group} dispose={null}>
      <group name="Scene">
        <group name="Armature">
          <primitive object={nodes.index_0} />
          <primitive object={nodes.middle_0} />
          <primitive object={nodes.ring_0} />
          <primitive object={nodes.pinky_0} />
          <primitive object={nodes.thumb_0} />
          <group name="Icosphere">
            <skinnedMesh name="Icosphere001" geometry={nodes.Icosphere001.geometry} material={materials.skin} skeleton={nodes.Icosphere001.skeleton} />
            <skinnedMesh ref={nailRef} onPointerEnter={()=>{document.documentElement.style.cursor = "pointer"}}
           onClick={()=>{console.log("A")}}
           onPointerLeave={()=>{document.documentElement.style.cursor = "default"}} name="Icosphere001_1" geometry={nodes.Icosphere001_1.geometry} material={materials.nail} skeleton={nodes.Icosphere001_1.skeleton}>
              
            </skinnedMesh>
          </group>
        </group>
      </group>
    </group>
  )
}
useGLTF.preload(handUrl)

