import { createRoot } from 'react-dom/client'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Hand } from './Hand'
import { Camera } from './Camera'
import { Lighting } from './Lighting'
import { BoxGeometry, Color, Euler, Mesh, MeshBasicMaterial, PlaneGeometry, SphereGeometry, Vector3 } from 'three'
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette, GodRays } from '@react-three/postprocessing'
import { BlendFunction, EdgeDetectionMode, EffectPass, GodRaysEffect, KernelSize, SMAAEffect, SMAAPreset } from 'postprocessing'
import { Html } from '@react-three/drei'
import ScreenshotUrl from "../public/ss.png"
import gsap from "gsap"

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef<THREE.Mesh>(null!)
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (mesh.current.rotation.x += delta))
  // Return view, these are regular three.js elements expressed in JSX
  
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => setActive(!active)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
    </mesh>
  )
}
function Effects({sRef}){
  if(sRef){
    console.log(sRef)
    return(
      <EffectComposer>
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
        <GodRays sun={sRef}
          blendFunction={BlendFunction.SCREEN} // The blend function of this effect.
          samples={120} // The number of samples per pixel.
          density={0.98} // The density of the light rays.
          decay={0.96} // An illumination decay factor.
          weight={0.4} // A light ray weight factor.
          exposure={0.3} // A constant attenuation coefficient.
          clampMax={1} // An upper bound for the saturation of the overall effect.
          kernelSize={KernelSize.SMALL} // The blur kernel size. Has no effect if blur is disabled.
          blur={0.05} // Whether the god rays should be blurred to reduce artifacts.
        />
      </EffectComposer>
    )
  }else{
    return <></>
  }
}
function showSS(camera,clickCount,point,htmlRef, orbitRef){
  if(clickCount == 0){
    document.getElementById("ss")!.style.visibility = "visible"
    let textNode = document.getElementById("text")!
    textNode.innerHTML = "fuck you"
    textNode.style.pointerEvents = "none"
    textNode.style.backgroundColor = "rgba(120,10,10,0)"
    gsap.to(textNode,{
      backgroundColor: "rgba(200,100,100,1)",
      duration:0.5,
      delay:1,
      ease:"power1.out"
    }).then(()=>{
      textNode.style.pointerEvents = "all"
    })
  }else if(clickCount == 1){
    gsap.to(camera.position,{
      x:2,
      y:1,
      z:5,
      duration:2,
      ease:"power1.inOut"
    })
    gsap.to(camera.rotation,{
      x:0,
      y:0,
      z:-0.4,
      duration:2,
      ease:"power1.inOut"
    })
    gsap.delayedCall(1,()=>{
      point.current()
      htmlRef.current.scale.set(new Vector3(0,0,0))
      orbitRef.current.enablePan = true
      orbitRef.current.enableZoom = true
      orbitRef.current.enableRotate = true
    })
  }
}
export function Scene({...props}){
  const {scene,camera} = useThree()
  const funcRef = useRef<Function>(null!)
  const htmlRef = useRef<THREE.Mesh>(null!)
  const [sunRef, setSunRef] = useState<THREE.Mesh>(null!)
  const orbitRef = useRef<any>(null!)
  scene.background = new Color(0,0,0)
  let clickCount = 0
  return(
    <>
      <mesh ref={htmlRef} material={new MeshBasicMaterial({transparent:true})} rotation={new Euler(-0.46,-0.03,-0.085)} position={[0.057, 2, -0.214]}>
        {/* Drei's HTML component can "hide behind" canvas geometry */}
        <Html center={true} scale={0.006} position={[0.001, 0.024, 0.03]} transform occlude={"blending"}>
          <div style={{color:"white",gap:"20px",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",margin:0,width:1000,height:1000,backgroundColor:"rgba(50,0,0)"}}>
            <div id={"text"} onClick={() => {showSS(camera,clickCount,funcRef,htmlRef,orbitRef);clickCount++}} style={{cursor:"pointer",border:"4px white solid",backgroundColor:"rgba(120,10,10,1)",borderRadius:"10px",padding:"20px",fontSize:"100px", userSelect:"none"}}>Click me</div>
            <div style={{visibility:"hidden",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column",gap:"20px"}} id={"ss"}>
              <img draggable={false} style={{borderRadius:"20px", userSelect:"none"}} src={ScreenshotUrl} width={600} height={600}/>
              <p style={{fontSize:"50px"}}>Happy birthday fucker</p>
            </div>
          </div>
        </Html>
      </mesh>
      <group {...props}>
        <Lighting sRef={sunRef => setSunRef(sunRef)}/>
          <Hand funcRef={funcRef}/>
        <Camera orbitRef={orbitRef}/>
      </group>
      <Effects sRef={sunRef}/>
    </>
  )
}